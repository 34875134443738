import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant, { Toast } from 'vant';
import 'vant/lib/index.css'
import sessionStorage from '@/utils/session'
import { loadingFu } from '@/utils/utils'
import 'vant/lib/index.css';
import 'mavon-editor/dist/css/index.css'

import '@/utils/setRem'
// 重置默认css
import "@/style/reset.css"
import QRCode from "qrcode"
/**
 *  全局提示组件
 */
import dialog from '@/components/dialog/index'
Vue.use(Vant);
/**
 * 全局注册组件
*/
import components from '@/components/base'
Vue.prototype.ImgURL = 'https://www.huacein.com/download'
Vue.prototype.$session_storage = sessionStorage;
Vue.prototype.$loading = loadingFu;
Vue.prototype.$Toast = Toast;
Vue.config.productionTip = false;
Vue.use(components)

// 未登录弹窗
// 创建一个Vue实例对像并把弹窗放进去
const dialogBox = new Vue(dialog)
// 创建一个`div`标签并将实例挂载
dialogBox.$mount(document.createElement('div'))
// 将弹窗组件的Dom元素添加到body里
document.body.appendChild(dialogBox.$el)
Vue.prototype.$dialogBox = dialogBox

Vue.use(QRCode)
// 配置调试工具
// 如果在开发环境下，引入移动端console调试插件
// if (process.env.NODE_ENV !== "production") {
// const VConsole = require("vconsole");
// const Vc = new VConsole();
// }
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");

<template>
    <div>
        <!-- 自定义图标 -->
        <van-popup v-model="dialogShow" round closeable close-icon="close" @click-close-icon="toPageFu">
            <div class="popup_main flex_column flex_align text_align">
                <div class="popup_title">温馨提示</div>
                <div class="popup_text">{{text}}</div>
                <div class="btn_con flex_around">
                    <button class="btn_1 btn" @click="toPageFu">确认</button>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import router from '@/router'
export default {
    data() {
        return {
            dialogShow:false,
            text:'您还未登录，请先登录后再预约',
        };
    },
    methods: {
        hidePopup() {
            this.dialogShow = false
        },
        toPageFu(){
            this.dialogShow = false
            router.push("/login");
        },
        // 触发
        open(item) {
            if(item){
                this.text = item
            }
            this.dialogShow = true
        }
    },
};
</script>

<style lang="scss" scoped>
.popup_main {
    width: 499rem;
    min-height: 402rem;
    background: #ffffff;
    border-radius: 20rem;
    padding: 80rem 20rem 40rem;
    box-sizing: border-box;
    .popup_title {
        font-size: 35rem;
        font-weight: 400;
        color: #000000;
    }
    .popup_text {
        font-size: 32rem;
        font-weight: 400;
        color: #747474;
        margin-top: 54rem;
        margin-bottom: 80rem;
        line-height: 42rem;
    }
    .btn_con {
        width: 100%;
        .btn {
            border-radius: 38rem;
            background: linear-gradient(0deg, #ff6d3a 0%, #ff0704 100%);
            height: 75rem;
            line-height: 75rem;
            color: #fff;
            font-size: 32rem;
        }
        .btn_1 {
            width: 393rem;
        }
        .btn_2 {
            width: 201rem;
        }
        .btn_3 {
            width: 201rem;
            background: #e9e9e9;
            color: #000000;
        }
    }
}
</style>
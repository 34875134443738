<template>
    <div>
        <!-- 自定义图标 -->
        <van-popup v-model="dialogShow" round closeable close-icon="close" @click-close-icon="hidePopup">
            <div class="popup_main flex_column flex_align text_align">
                <div class="popup_title">{{title?title:'温馨提示'}}</div>
                <div class="popup_text" v-html="popupText"></div>
                <div class="btn_con flex_around">
                    <template v-if="functionBtn">
                        <button class="btn_3 btn" @click="hidePopup">取消</button>
                        <button class="btn_2 btn" @click="authenticationFu">去认证</button>
                    </template>
                    <template v-else>
                        <button class="btn_1 btn" @click="hidePopup">确认</button>
                    </template>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
export default {
    name: "dialogCom",
    props: {
        popupShow: {
            type: Boolean,
            default: false,
        },
        popupText: {
            type: String,
            default: "",
        },
        functionBtn: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
    },
    computed: {
        dialogShow: {
            get() {
                return this.popupShow;
            },
            set(v) {},
        },
    },
    data() {
        return {};
    },
    mounted(){
        // this.popupText = this.popupText.replace("\n", " <br>");
    },
    methods: {
        hidePopup() {
            this.$emit("hidePopup");
        },
        authenticationFu() {
            if (this.$store.state.user.userInfo.realFlag) {
                this.$router.push("/authenticationCar");
            } else {
                this.$router.push("/authenticationName");
            }
            this.$emit("hidePopup");
        },
    },
};
</script>

<style lang="scss" scoped>
.popup_main {
    width: 499rem;
    min-height: 402rem;
    background: #ffffff;
    border-radius: 20rem;
    padding: 80rem 20rem 80rem;
    box-sizing: border-box;
    .popup_title {
        font-size: 35rem;
        font-weight: 400;
        color: #000000;
    }
    .popup_text {
        font-size: 32rem;
        font-weight: 400;
        color: #747474;
        margin-top: 54rem;
        margin-bottom: 80rem;
        line-height: 42rem;
        text-align: left;
    }
    .btn_con {
        width: 100%;
        .btn {
            border-radius: 38rem;
            background: linear-gradient(0deg, #ff6d3a 0%, #ff0704 100%);
            height: 75rem;
            line-height: 75rem;
            color: #fff;
        }
        .btn_1 {
            width: 393rem;
        }
        .btn_2 {
            width: 201rem;
        }
        .btn_3 {
            width: 201rem;
            background: #e9e9e9;
            color: #000000;
        }
    }
}
</style>
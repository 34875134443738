import sessionStorage from '@/utils/session'
const state = {
    layoutIndex: sessionStorage.getStorage('LAYOUTINDEX') * 1 || 0, // 出发地
}

const mutations = {
    SETLAYOUTINDEX: (state, data) => {
        state.layoutIndex = data
        sessionStorage.addStorage('LAYOUTINDEX', data)
    },
}

const actions = {
    set_layoutIndex({ commit }, data) {
        commit('SETLAYOUTINDEX', data)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

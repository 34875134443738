import Vue from "vue";
import Vuex from "vuex";
import getters from './getters'
import loaction from "./modules/loaction";
import layout from "./modules/layout";
import release from "./modules/release"
import user from "./modules/user"

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        loaction,
        layout,
        release,
        user
    },
    getters
});


/**
 * 全局设置组件方法，组件需要设置name
 */
const components = require.context('./', false, /\.vue$/) // require.context获取指定目录下符合条件的文件，这里获取所有base目录下的组件
export default Vue => {
    components.keys().map(item => {
        Vue.component(components(item).default.name, components(item).default)
        return item
    })
}

<template>
    <div>
        <div :class="['nav_con', 'flex_align' , {'transparent' :transparent}]">
            <img class="back_btn" v-if="returnBtn" @click="returnPageFu" :src="leftArrow" />
            <div class="header_title">{{ title }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "navBar",
    props: {
        transparent: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            title: "", // 标题
            returnBtn: false, // 返回按钮
            leftArrow: require("@/images/left_arrow.png"),
        };
    },
    created() {
        this.title = this.$route.meta.title;
        this.returnBtn = this.$route.meta.returnBtn;
    },
    methods: {
        // 返回上一页
        returnPageFu() {
            console.log(this.$router, "routertre");
            this.$router.back();
        },
    },
};
</script>

<style scoped lang="scss">
.nav_con {
    height: 88rem;
    width: 100%;
    justify-content: center;
    z-index: 999;
    background: #fff;

    .back_btn {
        width: 32rem;
        height: 32rem;
        position: absolute;
        left: 32rem;
    }

    .header_title {
        font-size: 34rem;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
    }
}
.transparent {
    background: transparent;
}
</style>

<template>
    <div id="app">
        <!-- <transition name="fade" mode="out-in"> -->
        <!--需要缓存的keep-alive配置 展示内容-->
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <!--不需要缓存的keep-alive 配置 展示内容-->
        <router-view v-if="!$route.meta.keepAlive" />
        <!-- </transition> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            leftArrow: require("@/images/left_arrow.png"),
        };
    },
    watch: {
        $route(to, from) {
            // console.log(to, from, "to , from");
        },
    },
    mounted(){
        // document.getElementsByTagName('body')[0].style.overflow = 'hidden'
        // document.getElementsByTagName('body')[0].style.position = 'fixed'
    }
};
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 28rem;
    overflow: hidden;
}

nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.is_pc {
    .van-tabbar--fixed {
        width: 375px;
        left: 50%;
        transform: translateX(-50%);
    }

    .van-calendar__popup {
        width: 375px;
        left: 50%;
        transform: translateX(-50%);
    }
}
// .fade-enter-active,
// .fade-leave-active {
//     transition: transform 0.3s ease-out;
// }

// .fade-enter,
// .fade-leave-to {
//     transform: translateX(30%);
// }
</style>

import sessionStorage from '@/utils/session'
const state = {
    switchRelease: sessionStorage.getStorage('SETSWITCHRELEASE') || true,
    carOwner: {}, // 车主发布信息
    owner: {},  // 物主发布信息
}

const mutations = {
    SETSWITCHRELEASE: (state, data) => {
        state.switchRelease = data
        sessionStorage.addStorage('SETSWITCHRELEASE', data)
    },
    SETCAROWNER: (state, data) => {
        console.log(data);
        state.carOwner = data
    },
    SETOWNER: (state, data) => {
        state.owner = data
    },
}

const actions = {
    set_switch_release({ commit }, data) {
        commit('SETSWITCHRELEASE', data)
    },
    set_car_owner({ commit }, data) {
        commit('SETCAROWNER', data)
    },
    set_owner({ commit }, data) {
        commit('SETOWNER', data)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

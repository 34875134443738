import Vue from "vue";
import VueRouter from "vue-router";// 引入方式
import dialogBox from '@/components/dialog'
Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import('@/views/login'),
        meta: { title: '登陆', returnBtn: false, keepAlive: true }
    },
    {
        path: "/loginProtocol",
        name: "loginProtocol",
        component: () => import('@/views/login/protocol'),
        meta: { title: '用户协议', returnBtn: true, noAuthority: true, }
    },
    {
        path: "/nameProtocol",
        name: "nameProtocol",
        component: () => import('@/views/authentication/nameProtocol'),
        meta: { title: '隐私条款', returnBtn: true }
    },
    {
        path: "/verified",
        name: "verified",
        component: () => import('@/views/verified'),
        meta: { title: '实名认证', returnBtn: true }
    },
    {
        path: '/',
        component: () => import('@/views/layout'),
        redirect: '/layout/home',
        children: [
            {
                path: '/',
                component: () => import('@/views/layout/home'),
                name: 'home',
                meta: { title: '首页', returnBtn: false, noAuthority: true, }
            },
        ]
    },
    {
        path: '/release',
        component: () => import('@/views/layout'),
        redirect: '/layout/release',
        children: [
            {
                path: '/release',
                component: () => import('@/views/layout/release'),
                name: 'release',
                meta: { title: '发布', returnBtn: false }
            },
        ]
    },
    {
        path: '/commerce',
        component: () => import('@/views/layout'),
        redirect: '/layout/commerce',
        children: [
            {
                path: '/commerce',
                component: () => import('@/views/layout/commerce'),
                name: 'commerce',
                meta: { title: '商务', returnBtn: false }
            },
        ]
    },
    {
        path: '/my',
        component: () => import('@/views/layout'),
        redirect: '/layout/my',
        children: [
            {
                path: '/my',
                component: () => import('@/views/layout/my'),
                name: 'my',
                meta: { title: '我的', returnBtn: false }
            },
        ]
    },
    {
        path: "/locationSelect",
        name: "locationSelect",
        component: () => import('@/views/locationSelect'),
        meta: { title: '出发地', returnBtn: true, noAuthority: true, }
    },
    {
        path: "/releaseProtocol",
        name: "releaseProtocol",
        component: () => import('@/views/layout/release/protocol'),
        meta: { title: '协议', returnBtn: true, noAuthority: true, }
    },
    {
        path: "/predetermineList",
        name: "predetermineList",
        component: () => import('@/views/home/predetermine/list'),
        meta: { title: '预定', returnBtn: true, noAuthority: true, }
    },
    {
        path: "/takeList",
        name: "takeList",
        component: () => import('@/views/home/takeList/index'),
        meta: { title: '带物列表', returnBtn: true }
    },
    {
        path: "/takeListDetail",
        name: "takeListDetail",
        component: () => import('@/views/home/takeList/takeListDetail'),
        meta: { title: '接单', returnBtn: true }
    },
    {
        path: "/takeUnsubscribe",
        name: "takeUnsubscribe",
        component: () => import('@/views/home/takeList/takeUnsubscribe'),
        meta: { title: '退订原因', returnBtn: true }
    },
    {
        path: "/selfBooking",
        name: "selfBooking",
        component: () => import('@/views/selfBooking'),
        meta: { title: '预定座位', returnBtn: true }
    },
    {
        path: "/selfBookingProtocol",
        name: "selfBookingProtocol",
        component: () => import('@/views/selfBooking/protocol'),
        meta: { title: '合乘协议', returnBtn: true }
    },
    {
        path: "/modifyPhone",
        name: "modifyPhone",
        component: () => import('@/views/selfBooking/modifyPhone'),
        meta: { title: '修改手机号', returnBtn: true }
    },
    {
        path: "/selfBookingDetail",
        name: "selfBookingDetail",
        component: () => import('@/views/selfBooking/detail'),
        meta: { title: '预定详情', returnBtn: true }
    },
    {
        path: "/travelDetails",
        name: "travelDetails",
        component: () => import('@/views/selfBooking/travelDetails'),
        meta: { title: '出行详情', returnBtn: true }
    },
    {
        path: "/unsubscribe",
        name: "unsubscribe",
        component: () => import('@/views/selfBooking/unsubscribe'),
        meta: { title: '退订原因', returnBtn: true }
    },
    {
        path: "/unsubscribeList",
        name: "unsubscribeList",
        component: () => import('@/views/selfBooking/unsubscribeList'),
        meta: { title: '预定详情', returnBtn: true }
    },
    {
        path: "/authenticationName",
        name: "authenticationName",
        component: () => import('@/views/authentication/name'),
        meta: { title: '车主认证', returnBtn: true }
    },
    {
        path: "/authenticationCar",
        name: "authenticationCar",
        component: () => import('@/views/authentication/car'),
        meta: { title: '车主认证', returnBtn: true }
    },
    {
        path: "/authenticationLicense",
        name: "authenticationLicense",
        component: () => import('@/views/authentication/license'),
        meta: { title: '车主认证', returnBtn: true }
    },
    {
        path: "/selectBrand",
        name: "selectBrand",
        component: () => import('@/views/authentication/selectBrand'),
        meta: { title: '车辆品牌', returnBtn: true }
    },
    {
        path: "/selectModel",
        name: "selectModel",
        component: () => import('@/views/authentication/selectModel'),
        meta: { title: '车辆型号', returnBtn: true }
    },
    {
        path: "/postingDetails",
        name: "postingDetails",
        component: () => import('@/views/postingDetails'),
        meta: { title: '发布详情', returnBtn: true }
    },
    {
        path: "/myWallet",
        name: "myWallet",
        component: () => import('@/views/my/wallet'),
        meta: { title: '我的钱包', returnBtn: true }
    },
    {
        path: "/withdraw",
        name: "withdraw",
        component: () => import('@/views/my/withdraw'),
        meta: { title: '提现', returnBtn: true }
    },
    {
        path: "/myFaith",
        name: "myFaith",
        component: () => import('@/views/my/faith'),
        meta: { title: '诚信值', returnBtn: true }
    },
    {
        path: "/myTrip",
        name: "myTrip",
        component: () => import('@/views/my/trip'),
        meta: { title: '我的行程', returnBtn: true }
    },
    {
        path: "/myEvaluate",
        name: "myEvaluate",
        component: () => import('@/views/my/evaluate/index'),
        meta: { title: '评价', returnBtn: true }
    },
    {
        path: "/myEvaluateList",
        name: "myEvaluateList",
        component: () => import('@/views/my/evaluate/list'),
        meta: { title: '我的评价', returnBtn: true }
    },
    {
        path: "/myNickname",
        name: "myNickname",
        component: () => import('@/views/my/nickname'),
        meta: { title: '修改昵称', returnBtn: true }
    },
    {
        path: "/myHelp",
        name: "myHelp",
        component: () => import('@/views/my/help'),
        meta: { title: '帮助中心', returnBtn: true }
    },
    {
        path: "/myComplaint",
        name: "myComplaint",
        component: () => import('@/views/my/help/complaint'),
        meta: { title: '投诉反馈', returnBtn: true }
    },
    {
        path: "/myPlatform",
        name: "myPlatform",
        component: () => import('@/views/my/help/platform'),
        meta: { title: '联系平台', returnBtn: true }
    },
    {
        path: "/familiarquestion",
        name: "familiarquestion",
        component: () => import('@/views/my/help/familiarquestion'),
        meta: { title: '问题管理', returnBtn: true }
    },
    {
        path: "/answer",
        name: "answer",
        component: () => import('@/views/my/help/answer'),
        meta: { title: '问题', returnBtn: true }
    },
    {
        path: "/duty",
        name: "duty",
        component: () => import('@/views/my/duty/duty'),
        meta: { title: '有责无责退订说明', returnBtn: true }
    },
    {
        path: "/banner",
        name: "banner",
        component: () => import('@/views/my/help/banner'),
        meta: { title: '分享', returnBtn: true }
    },
    {
        path: "/weixinPay",
        name: "weixinPay",
        component: () => import('@/views/my/payMent/weixinPay'),
        meta: { title: '微信支付', returnBtn: true, noAuthority: true, }
    },
    {
        path: "/shareList",
        name: "shareList",
        component: () => import('@/views/Share/shareList'),
        meta: { title: '分享', returnBtn: true, noAuthority: true, }
    },
    {
        path: "/shareCode",
        name: "shareCode",
        component: () => import('@/views/Share/shareCode'),
        meta: { title: '分享详情', returnBtn: true, noAuthority: true, }
    },
    {
        path: "/tripCarDetail",
        name: "tripCarDetail",
        component: () => import('@/views/my/trip/carDetail'),
        meta: { title: '车主行程详情', returnBtn: true }
    },
    {
        path: "/tripOwnerDetail",
        name: "tripOwnerDetail",
        component: () => import('@/views/my/trip/ownerDetail'),
        meta: { title: '物主带货详情', returnBtn: true }
    },

];

const router = new VueRouter({
    // mode: "hist",
    base: process.env.BASE_URL,
    routes,
});

/**
 * 路由拦截
 *
 */
router.beforeEach(async (to, from, next) => {
    document.title = '花橙专线'
    //判断是否登录进入页面
    const token = localStorage.getItem('token') || false
    const moduleName = to.meta.noAuthority
    if (moduleName) {
        next()
        return
    }
    if (to.path == '/login') {
        if (!token) {
            // sessionStorage.clear()
            localStorage.clear()
            next()
        } else {
            next('/')
        }
    } else {
        if (!token) {
            // sessionStorage.clear()
            localStorage.clear()
            next(`/login`)
            // Vue.prototype.$dialogBox.open()
        } else {
            next()
        }
    }

})

export default router;

const getters = {
    departure: state => state.loaction.departure,
    destination: state => state.loaction.destination,
    vehicleBrand: state => state.loaction.vehicleBrand,
    layoutIndex: state => state.layout.layoutIndex,
    getwxConfig: state => state.loaction.getwxConfig,
    setLoaction: state => state.loaction.setLoaction,
    startTime: state => state.loaction.startTime,
    endTime: state => state.loaction.endTime,
    switchRelease: state => state.release.switchRelease,
    userInfo: state => state.user.userInfo,
}
export default getters

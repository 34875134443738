import sessionStorage from '@/utils/session'
const state = {
    userInfo: sessionStorage.getStorage('SETUSERINFO') || {}, // 个人信息
}

const mutations = {
    SETUSERINFO: (state, data) => {
        state.userInfo = data
        sessionStorage.addStorage('SETUSERINFO', data)
    },
}

const actions = {
    set_userInfo({ commit }, data) {
        commit('SETUSERINFO', data)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

import sessionStorage from '@/utils/session'
import { setStartFu, setStartFu1 } from '@/utils/utils'
const state = {
    departure: sessionStorage.getStorage('SETDEPARTURE') || '', // 出发地
    destination: sessionStorage.getStorage('SETDESTINATION') || '', // 目的地
    switchCondition: Boolean(sessionStorage.getStorage('SETSWITCHONDITION')), // 判断是否调换位置
    vehicleBrand: sessionStorage.getStorage('SETVEHICLEBRAND') || {},  // 车辆品牌
    getwxConfig: sessionStorage.getStorage('GETWXCONFIG') || false,  // 微信配置
    startTime: sessionStorage.getStorage('SETSTARTTIME') || setStartFu(),  // 开始时间 
    endTime: sessionStorage.getStorage('SETENDTIME') || setStartFu1(),  // 结束时间
    setLoaction: sessionStorage.getStorage('SETLOACTION') || false,  // 结束时间
}

const mutations = {
    SETDEPARTURE: (state, data) => {
        state.departure = data
        sessionStorage.addStorage('SETDEPARTURE', data)
    },
    SETDESTINATION: (state, data) => {
        state.destination = data
        sessionStorage.addStorage('SETDESTINATION', data)
    },
    SETSWITCHONDITION: (state, data) => {
        state.switchCondition = !state.switchCondition
        sessionStorage.addStorage('SETSWITCHONDITION', state.switchCondition)
    },
    SETVEHICLEBRAND: (state, data) => {
        state.vehicleBrand = data
        sessionStorage.addStorage('SETVEHICLEBRAND', state.vehicleBrand)
    },
    SETWXCONFIG: (state, data) => {
        state.getwxConfig = data
        sessionStorage.addStorage('GETWXCONFIG', data)
    },
    SETSTARTTIME: (state, data) => {
        state.startTime = data
        sessionStorage.addStorage('SETSTARTTIME', data)
    },
    SETENDTIME: (state, data) => {
        state.endTime = data
        sessionStorage.addStorage('SETENDTIME', data)
    },
    SETLOACTION: (state, data) => {
        state.setLoaction = true
        sessionStorage.addStorage('SETLOACTION', true)
    }
}

const actions = {
    set_departure({ commit }, data) {
        commit('SETDEPARTURE', data)
    },
    set_destination({ commit }, data) {
        commit('SETDESTINATION', data)
    },
    set_switch_ondition({ commit }, data) {
        commit('SETSWITCHONDITION')
    },
    set_vehicle_brand({ commit }, data) {
        commit('SETVEHICLEBRAND', data)
    },
    set_wx_config({ commit }, data) {
        commit('SETWXCONFIG', data)
    },
    set_start_time({ commit }, data) {
        commit('SETSTARTTIME', data)
    },
    set_end_time({ commit }, data) {
        commit('SETENDTIME', data)
    },
    set_loaction({ commit }, data) {
        commit('SETLOACTION', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

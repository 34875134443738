import { isMobile } from "./utils"
function remSize() {
    let deviceWidth
    /**
     * 判断是否为pc端
     */
    if (isMobile()) {
        deviceWidth = document.documentElement.clientWidth || window.innerWidth
    } else {
        deviceWidth = 375
    }
    document.querySelector('html').style.width = deviceWidth + 'px'
    document.querySelector('html').style.fontSize = deviceWidth / 750 + 'px';
}
remSize()
//当窗口发生变化时要调用函数
window.onresize = function () {
    remSize()
}
import { Toast } from 'vant';
// 全局加载动画

export const loadingFu = (duration = 0, text) => {
    const loading = Toast.loading({
        message: text || '加载中...',
        duration, // 持续展示 toast
        forbidClick: true,
        getContainer: "#app",
        className: 'z_index'
    });
    return loading
}

export function isMobile() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        return true // 手机端
    } else {
        return false // alert('PC端')
    }
}

/**
 *
 *多语言调用 封装
 * @export
 * @param {*} title 多语言调用key
 * @returns
 */
export function getLang(title) {
    const hasKey = this.$te(title)
    if (hasKey) {
        const text = this.$t(title)
        return text
    }
    return title
}

/**
 *合并object对象，新增相同key值报错
 *
 * @export
 * @returns
 */
export function mergeObject() {
    const data = Array.prototype.splice.call(arguments, 0)
    return merges(data)
}

/**
 * 合并
 * @param {*} data
 * @returns
 */
function merges(data) {
    let isAlert = false;
    for (let i = 1; i < data.length; i++) {
        for (const j in data[i]) {
            if (Object.prototype.hasOwnProperty.call(data[0], j)) {
                isAlert = true;
                console.error('接口重名：' + j, '&&&&', '涉及接口：==>' + data[i][j].url, data[0][j].url)
            } else {
                data[0][j] = data[i][j]
            }
        }
    }
    if (isAlert) {
        alert('接口重名，具体见conso日志')
    }
    return data[0];
}

/**
 * 获取url参数值
 * @param {*} name 参数 名称
 * @returns
 */
export function getParamsBydecodeURI(name) {
    const result = (window.location.href).match(new RegExp("[?&]" + name + "=([^&#]+)", "i"))
    if (result == null || result.length < 1) {
        return ""
    }
    return result[1]
}

/**
 * 获取服务器时间
 * @returns date 时间戳
 */
export function getServerTime() {
    return new Promise((resolve) => {
        const request = new XMLHttpRequest()
        request.open("GET", "/", true)
        request.send()
        request.onreadystatechange = function () {
            const serverTime = request.getResponseHeader("Date")
            document.write(serverTime)
            if (serverTime) {
                resolve(Date.parse(serverTime))
            } else {
                resolve(new Date().valueOf)
            }
        }
    })
}

/**
 *  设置对象拼接
 */
export const splicingObjects = (obj) => {
    const arr = []
    for (const k in obj) {
        arr.push(`${k}=${obj[k]}`)
    }
    return arr.join('&')
}

export const setStartFu = () => {
    const now = new Date();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    return month + '月' + "-" + day + '日';
}
export const setStartFu1 = () => {
    const now = new Date();
    const date = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return month + '月' + "-" + day + '日';
}
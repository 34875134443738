export default {
    /**
     * 添加
     * @param {*} key 键名
     * @param {*} params 值
     */
    addStorage(key, params) {
        if (!key) {
            return
        }
        if (typeof (params) == "undefined") {
            params = null
        }
        sessionStorage.setItem(key, JSON.stringify(params))
    },
    /**
     * 取数据
     * @param {*} key 键名
     * @returns
     */
    getStorage(key) {
        if (!key) {
            return
        }
        return JSON.parse(sessionStorage.getItem(key))
    },

    /**
     * 删除数据
     * @param {*} key 键名
     */
    delStorage(key, pre) {
        if (!key) {
            return
        }
        sessionStorage.removeItem(key)
    },
    /**
     * 清空数据
     */
    delAllStorage() {
        sessionStorage.clear()
    },

}

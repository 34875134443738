<template>
    <header class="header_con flex_between text_align flex_align" :class="[step]">
        <div class="icon_con">
            <img class="icon" :src="step == 'first_step' ? name_active_icon : name_icon" alt />
            <p class="icon_text text_1">实名认证</p>
        </div>
        <div class="line_con">
            <img v-if="step == 'first_step'" class="line" :src="line_1" alt />
            <div v-else class="line line_border"></div>
        </div>
        <div class="icon_con">
            <img class="icon" :src="step == 'second_step' ? car_active_icon : car_icon" alt />
            <p class="icon_text text_2">驾驶证认证</p>
        </div>
        <div class="line_con">
            <img v-if="step == 'second_step'" class="line" :src="line_2" alt />
            <div v-else class="line line_border"></div>
        </div>
        <div class="icon_con">
            <img class="icon" :src="step == 'third_step' ? license_active_icon : license_icon" alt />
            <p class="icon_text text_3">行驶证认证</p>
        </div>
    </header>
</template>

<script>
export default {
    name: "authenticationTop",
    props: {
        step: {
            type: String,
            default: "first_step",
        },
    },
    data() {
        return {
            car_active_icon: require("@/images/authentication/car_active_icon.png"),
            car_icon: require("@/images/authentication/car_icon.png"),
            license_active_icon: require("@/images/authentication/license_active_icon.png"),
            license_icon: require("@/images/authentication/license_icon.png"),
            name_active_icon: require("@/images/authentication/name_active_icon.png"),
            name_icon: require("@/images/authentication/name_icon.png"),
            line_1: require("@/images/authentication/line_1.png"),
            line_2: require("@/images/authentication/line_2.png"),
        };
    },
};
</script>

<style scoped lang='scss'>
.header_con {
    width: 750rem;
    height: 139rem;
    background: #f9fbfe;
    padding: 0 38rem;
    box-sizing: border-box;
    .icon_con {
        width: 150rem;
        .icon {
            width: 42rem;
            height: 42rem;
            margin-bottom: 20rem;
        }
        .icon_text {
            font-size: 26rem;
            font-weight: 400;
        }
    }
    .line_con {
        width: 50rem;
        height: 10rem;
        .line {
            width: 239rem;
            height: 10rem;
            margin-left: -70rem;
            margin-top: -25rem;
        }
        .line_border {
            width: 200rem;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            margin-left: -70rem;
            margin-top: -20rem;
        }
    }
}
.first_step {
    .text_1 {
        color: #eb6248;
    }
}
.second_step {
    .text_2 {
        color: #7a8ff9;
    }
}
.third_step {
    .text_3 {
        color: #4bb74f;
    }
}
</style>